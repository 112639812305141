import React, { useContext, useState, useEffect, useRef } from 'react'
import { Box, Stack, Avatar, Text  } from '@chakra-ui/core'
import { RootContext } from '../../utils/root-context';

function Chat ({chatData, setChatData, fullname, toUserId, bookingId}) {
  const { authBody } = useContext(RootContext)
  const contentRef = useRef(null)

  const fetchChats = () => {
    fetch(process.env.REACT_APP_URL + '/v1/chats?to_user_id=' + toUserId + '&booking_id=' + bookingId + '&limit=0', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      }
    })
    .then((response) => response.json())
    .then((result) => {
      if (result.success === true) {
        setChatData(result.data)
        contentRef.current.scrollTop = contentRef.current.scrollHeight;
      }
    })
    .catch((error) => {
      console.error('Error:', error)
    });
  }

  const ChatItemReciver = ({chat_id, content, created_at}) => {
    return (
      <Stack align="center" borderTop="1px" borderColor="gray.100" p={2} isInline key={chat_id}>
        <Avatar size="sm" name={fullname} />
        <Box pl={2} >
          <Text fontSize="xs" color="gray.200">{created_at}</Text>
          <Text fontSize="sm">{content}</Text>
        </Box>
      </Stack>
    )
  }

  const ChatItemSender = ({chat_id, content, created_at}) => {
    return (
      <Stack align="center" justify="flex-end" borderTop="1px" borderColor="gray.100" p={2} isInline key={chat_id}>
        <Box pr={2} textAlign="right">
          <Text fontSize="xs" color="gray.200">{created_at}</Text>
          <Text fontSize="sm">{content}</Text>
        </Box>
        <Avatar size="sm" name={authBody.name} />
        </Stack>
    )
  }

  useEffect(
    () => {
      fetchChats()
    }, []
  );

  return (
    <Box overflow="auto" maxHeight={'100%'} ref={contentRef}>
      {
        chatData.map(item => {
          return item.user_id === authBody.user_id ? <ChatItemSender key={item.chat_id} {...item} /> : <ChatItemReciver key={item.chat_id} {...item} />
        })
      }
    </Box>
  );
}

export default Chat
