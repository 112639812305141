import React, { useState, useContext } from 'react'
import { Box, useDisclosure } from '@chakra-ui/core'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import ReactTooltip from 'react-tooltip'
import { RootContext } from '../../utils/root-context';
import ModalComponent from './modal'

import './fullcalendar.scss'

function Dashboard () {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ events, setEvent ] = useState(null)
  const [ selectedDate, setSelectedDate ] = useState(null)
  const [ modalContent, setModalContent ] = useState(null)
  const [ modalInitValue, setModalInitValue ] = useState(null)
  const {authBody} = useContext(RootContext);

  const handleDateClick = info => {
    const item = events.find(({start}) => start === info.dateStr)
    if (item && item.modal !== undefined) {
      setModalContent(item.modal)
      setModalInitValue({day_id: item.day_id, price: item.price})
    }
    setSelectedDate(info.dateStr)
    onOpen();
  };
  const calendarRef = React.createRef()
  const calendarUpdate = () => {
    console.log(calendarRef)
    //let calendarApi = calendarRef.current.getApi()

    //calendarApi.rerenderEvents()
  }
  const handleEventPositioned = info => {

    //console.log(info.event.extendedProps)
    if (info.el.classList.contains('available')) {
      info.el.setAttribute("data-tip",'Day rate: €' + info.event.extendedProps.price);
      ReactTooltip.rebuild();
    }

  }
  const handleEventClick = info => {
    info.jsEvent.preventDefault();
    console.log(info.event)
  }

  const handleEvents = props => {
    const startDate = props.view.activeStart.getFullYear() + '-' + (props.view.activeStart.getMonth() + 1) + '-' + props.view.activeStart.getDate()
    const endDate = props.view.activeEnd.getFullYear() + '-' + (props.view.activeEnd.getMonth() + 1) + '-' + props.view.activeEnd.getDate()

    //console.log (events)

    fetch(process.env.REACT_APP_URL + '/v1/days?from_date='+startDate+'&to_date='+endDate+'&user_id='+authBody.user_id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      }
    })
    .then((response) => response.json())
    .then((result) => {
      //console.log('Success:', result)

      if (result.success === true) {
        setEvent(result.events)
      } else {
        //props.setFormState({state: 'success', message: 'Your information has been submitted. Please follow the e-mail to complete your signup!'})
      }

      //setIsSubmitting(false);
    })
    .catch((error) => {
      console.error('Error:', error)
      //props.setFormState({state: 'error', message: 'There was an error submiting your form: ' + error})
      //setIsSubmitting(false)
    });
  }

  const bulkActionButton = {
    text: 'Bulk actions',
    click: () => {
      setModalContent('bulk')
      onOpen()
    }
  }

  return (
    <Box p={4}>
      <FullCalendar
        defaultView="dayGridMonth"
        customButtons = {{bulkActionButton}}
        headerToolbar={{ start: 'prev,next today', center: 'title', end: 'bulkActionButton'}}
        plugins={[ dayGridPlugin, interactionPlugin ]}
        firstDay={ 1 }
        events={ events }
        dateClick={ handleDateClick }
        eventClick={ handleEventClick }
        datesSet={ handleEvents }
        eventDidMount={ handleEventPositioned }
        ref={ calendarRef }
      />
      <ModalComponent
        onClose={onClose}
        isOpen={isOpen}
        selectedDate={selectedDate}
        setEvent={setEvent}
        events={events}
        calendarUpdate={calendarUpdate}
        modalContent={modalContent}
        setModalContent={setModalContent}
        modalInitValue={modalInitValue}
      />
      <ReactTooltip backgroundColor="#2C3E50" effect="solid" html={true} />
    </Box>

  );
}

export default Dashboard
