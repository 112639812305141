import React, {useRef} from 'react'
import {
    Modal,
    ModalOverlay
} from '@chakra-ui/core';
import ModalContentSelect from './modal-content-select'
import ModalContentOptions from './modal-content-options';
import ModalContentEvent from './modal-content-event';
import ModalContentBulk from './modal-content-bulk';

function ModalItem({onClose, isOpen, ...props}) {
  const initialRef = useRef();

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered initialFocusRef={initialRef} closeOnOverlayClick={false}>
      <ModalOverlay />
      {props.modalContent === 'options' ? <ModalContentOptions onClose={onClose} {...props} /> : null}
      {props.modalContent === 'event' ? <ModalContentEvent onClose={onClose} {...props} /> : null}
      {props.modalContent === 'bulk' ? <ModalContentBulk onClose={onClose} {...props} /> : null}
      {props.modalContent === null ? <ModalContentSelect {...props} /> : null}
    </Modal>
  );
}

export default ModalItem
