import React, { useContext, useState, useEffect } from 'react'
import { useDisclosure, Box, Button, Avatar, Text } from '@chakra-ui/core'
import { RootContext } from '../../utils/root-context';
import Booking from './booking';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import ReactTooltip from 'react-tooltip'
import '../editor/editor.scss'
import '../dashboard/fullcalendar.scss'

function Page (props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ profileData, setProfileData ] = useState({})
  const [ onDate, setOnDate ] = useState(false)
  const [ imageData, setImageData ] = useState({'profile': false, 'background': false})
  const [ events, setEvent ] = useState(null)
  const { authBody } = useContext(RootContext)

  const handleDateClick = info => {
    const item = events.find(({start}) => start === info.dateStr)
    if (item && item.modal !== undefined) {
      //setModalContent(item.modal)
      //setModalInitValue({day_id: item.day_id, price: item.price})
    }
    //setSelectedDate(info.dateStr)
    onOpen();
  };

  const handleEventPositioned = info => {
    if (info.el.classList.contains('available')) {
      info.el.setAttribute("data-tip","Click to book now!<br />Day rate: €"+info.event.extendedProps.price);
      ReactTooltip.rebuild();
    }
  }

  const handleEventClick = info => {
    info.jsEvent.preventDefault();
    setOnDate(info.event.startStr)
    onOpen();
  }

  const handleEvents = props => {
    const startDate = props.view.activeStart.getFullYear() + '-' + (props.view.activeStart.getMonth() + 1) + '-' + props.view.activeStart.getDate()
    const endDate = props.view.activeEnd.getFullYear() + '-' + (props.view.activeEnd.getMonth() + 1) + '-' + props.view.activeEnd.getDate()

    //console.log (events)

    fetch(process.env.REACT_APP_URL + '/v1/days?from_date='+startDate+'&to_date='+endDate+'&user_id='+authBody.user_id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      }
    })
    .then((response) => response.json())
    .then((result) => {
      //console.log('Success:', result)

      if (result.success === true) {
        setEvent(result.events)
      } else {
        //props.setFormState({state: 'success', message: 'Your information has been submitted. Please follow the e-mail to complete your signup!'})
      }

      //setIsSubmitting(false);
    })
    .catch((error) => {
      console.error('Error:', error)
      //props.setFormState({state: 'error', message: 'There was an error submiting your form: ' + error})
      //setIsSubmitting(false)
    });
  }

  const updateImageData = (data, user_id) => {
    const images = {};
    if (data['profile']['uploaded'] !== false) {
      images['profile'] = process.env.REACT_APP_URL + '/assets/upload/' + user_id + '/' + data['profile']['file'];
    }
    if (data['background']['uploaded'] !== false) {
      images['background'] = process.env.REACT_APP_URL + '/assets/upload/' + user_id + '/' + data['background']['file'];
    }

    setImageData(images)
  }

  const fetchUsers = () => {
    fetch(process.env.REACT_APP_URL + '/v1/users/' + props.profile_url + '?users_content=true', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      }
    })
    .then((response) => response.json())
    .then((result) => {
      if (result.success === true) {
        setProfileData(result.data)
        updateImageData(result.data['users_content']['upload_context'], result.data['user_id'])
      }
    })
    .catch((error) => {
      console.error('Error:', error)
    });
  }

  const createMarkup = () => {
    return {__html: profileData['users_content']['context_html']};
  }

  useEffect(
    () => {
      fetchUsers();
    }, []
  );

  return (
    <Box p="4">
      <Box d="flex" mb="4" p="12" bg="#F1F1F1" bgImage={'url(' + imageData['background'] + ')'} bgSize="cover" alignContent="center">
        <Box w="20%">
          <Avatar name={profileData['firstname'] + ' ' + profileData['lastname']} src={imageData['profile']} size="2xl" /><br />
        </Box>
        <Box w="80%"><Text color="#FFF" fontSize="4xl" fontWeight="bold" textShadow="2px 2px 2px #aaa">{profileData['firstname'] ? profileData['firstname'] + ' ' + profileData['lastname'] : null}</Text></Box>
      </Box>
      <Box d={{ md: "flex" }} minH="40rem">
        <Box w={{md:'65%'}} pr="8">
          {profileData['users_content'] ? <div className="editor" dangerouslySetInnerHTML={createMarkup()}></div> : null}
        </Box>
        <Box w={{md:'35%'}} h="40rem" textAlign="right">
          <FullCalendar
            defaultView = "dayGridMonth"
            headerToolbar = {{ start: 'prev,next today', center: false, end: 'title'}}
            plugins = {[ dayGridPlugin, interactionPlugin ]}
            firstDay = { 1 }
            events = { events }
            eventClick = { handleEventClick }
            datesSet = { handleEvents }
            eventDidMount={ handleEventPositioned }
          />
        </Box>
      </Box>

      <Booking profileData={profileData} isOpen={isOpen} onClose={onClose} onDate={onDate}  />
      <ReactTooltip backgroundColor="#2C3E50" effect="solid" html={true} />
    </Box>
  );
}

export default Page
