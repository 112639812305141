import React from 'react'
import {
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
} from '@chakra-ui/core';

function ModalContentSelect(props) {
  const handleSubmit = (modalContent) => {
    props.setModalContent(modalContent)
  }

  return (
    <ModalContent>
      <ModalHeader>Set action for {props.selectedDate}</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6}>
      <Text>
        Please select something
      </Text>
      </ModalBody>
      <ModalFooter>
        <Button ml={4} variantColor="orange" onClick={() => handleSubmit('options')}>Day options</Button>
        <Button ml={4} variantColor="blue" onClick={() => handleSubmit('event')}>Event</Button>
      </ModalFooter>
    </ModalContent>
  );
}

export default ModalContentSelect
