import React, { useContext, useState, useEffect } from 'react'
import { Box, Button, Stack, Text } from '@chakra-ui/core'
import { RootContext } from '../../utils/root-context';

function Booking ({profileData, bookingData, setBookingData}) {
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const { authBody } = useContext(RootContext)

  const postBookings = () => {
    let postBody = {state: 5}
    fetch(process.env.REACT_APP_URL + '/v1/bookings/' + bookingData.booking_id, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      },
      body: JSON.stringify(postBody)
    })
    .then((response) => response.json())
    .then((result) => {
      if (result.success === true) {
        setBookingData({...bookingData, state: result.data.state})
        setIsSubmitting(false);
      }
    })
    .catch((error) => {
      console.error('Error:', error)
    });
  }

  const bookingClick = () => {
    postBookings()
    setIsSubmitting(true)
  }

  return (
    <Stack>
      {bookingData.state === 9 ? <Text>Booking has been accepted by the <b>{profileData.firstname + ' ' + profileData.lastname}</b></Text> : null}
      {bookingData.state === 5 ? <Text>You have started a booking with <b>{profileData.firstname + ' ' + profileData.lastname}</b> on <b>{bookingData.on_date}</b> with agreed price <b>€{bookingData.price}</b><br />Please wait until <b>{profileData.firstname + ' ' + profileData.lastname}</b> has accepted your offer!</Text> : null}
      {bookingData.state === 1 ?
        <>
          <Text pb="4">Book now will initate booking with <b>{profileData.firstname + ' ' + profileData.lastname}</b> on {bookingData.on_date} with agreed price €{bookingData.price}</Text>
          <Button color="blue" isLoading={isSubmitting} onClick={bookingClick}>Book now!</Button>
        </>
        :
        null
      }

    </Stack>
  );
}

export default Booking
