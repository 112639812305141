import React, { useContext, useState, useEffect } from 'react'
import { Box, Alert, AlertIcon } from '@chakra-ui/core'
import { RootContext } from '../../utils/root-context';
import ProfileForm from '../profile/form'

function Profile () {
  const [ formData, setFormData ] = useState({})
  const [ form, setFormState ] = useState({ state: null, message: null, messages: null})
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const { authBody } = useContext(RootContext)
console.log('profile')
  const fetchUser = () => {
    fetch(process.env.REACT_APP_URL + '/v1/users/id/' + authBody.user_id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      }
    })
    .then((response) => response.json())
    .then((result) => {
      if (result.success === true) {
        setFormData(result.data)
      }
    })
    .catch((error) => {
      console.error('Error:', error)
    });
  }

  const onSubmit = (data) => {
    setIsSubmitting(true)
    fetch(process.env.REACT_APP_URL + '/v1/users/' + authBody.user_id, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      }
    })
    .then((response) => response.json())
    .then((result) => {
      //console.log('Success:', result)
      if (result.success === false) {
        setFormData(result.data)
        setFormState({state: 'error', message: 'Following errors are on the form: ' + result.message})
      } else {
        setFormState({state: 'success', message: 'Your information has been submitted. Please follow the e-mail to complete your signup!'})
      }

      //setIsSubmitting(false);
    })
    .catch((error) => {
      //console.error('Error:', error)
      setFormState({state: 'error', message: 'There was an error submiting your form: ' + error})
      //setIsSubmitting(false)
    });
  }

  const FormAlert = ({status, message}) => {
    return(
      <Box mx="auto" mt={4} mb={5} width={['100%', 0.6, 0.3]}>
        <Alert status={status}>
          <AlertIcon />
          {message}
        </Alert>
      </Box>
    )
  }

  useEffect(
    () => {
      fetchUser();
    }, []
  );

  return ([
    form.state !== null && <FormAlert key="1" status={form.state} message={form.message} />,
    <ProfileForm key="2" onSubmit={onSubmit} isSubmitting={isSubmitting} formData={formData} />
  ]);
}

export default Profile
