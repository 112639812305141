import React, {useState, useContext} from 'react'
import {
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select
} from '@chakra-ui/core';
import useForm from '../../utils/use-form';
import {RootContext} from '../../utils/root-context';

function ModalContentEvent(props) {
  let initValues = {day_id: null, state: 10, title: null, price: null, city: null, country: null, on_date: props.selectedDate, on_time: null}
  if (props.modalInitValue !== null) {
    initValues = {...initValues, ...props.modalInitValue}
  }
  const { inputValues, handleChange } = useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {authBody} = useContext(RootContext)

  const handleClose = () => {
    if (isSubmitting) {
      return;
    }

    props.setModalContent(null)
    props.onClose()
  }
  const handleSubmit = (event) => {
    event.preventDefault();

    setIsSubmitting(true)
    fetch(process.env.REACT_APP_URL + '/v1/days', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      },
      body: JSON.stringify(inputValues),
    })
    .then((response) => response.json())
    .then((result) => {
      console.log('Success:', result)

      if (result.success === true) {
        props.onClose()
        //history.push('dashboard');
      } else {
        //props.setFormState({state: 'success', message: 'Your information has been submitted. Please follow the e-mail to complete your signup!'})
      }

      //setIsSubmitting(false);
    })
    .catch((error) => {
      console.error('Error:', error)
      //props.setFormState({state: 'error', message: 'There was an error submiting your form: ' + error})
      //setIsSubmitting(false)
    });
  }

  const options = ['01','02','03','04','05','06','07','08','09',10,11,12,13,14,15,16,17,18,19,20,21,22,23,'00'].map(item => <option key={item} value={item+':00:00'}>{item}:00</option>)

  return (
    <form onSubmit={handleSubmit}>
      <ModalContent>
        <ModalHeader>Set event for {props.selectedDate} </ModalHeader>
        <ModalCloseButton onClick={handleClose} />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>Select time</FormLabel>
            <Select placeholder="Time" name="on_time" onChange={handleChange}>{options}</Select>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Title</FormLabel>
            <Input ref={props.initialRef} placeholder="Title" name="title" onChange={handleChange} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Set price</FormLabel>
            <Input placeholder="Set price" name="price" onChange={handleChange} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>City</FormLabel>
            <Input placeholder="Set event city" name="city" onChange={handleChange} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Country</FormLabel>
            <Input placeholder="Set event country" name="country" onChange={handleChange} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button ml={4} variantColor="blue" isLoading={isSubmitting} type="submit">Save</Button>
        </ModalFooter>
      </ModalContent>
    </form>
  );
}

export default ModalContentEvent
