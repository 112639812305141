import { useState } from 'react'

function useForm(initialState) {
  const [inputValues, setValues] = useState(initialState);
  const handleChange = ({ target: { name, value, type, checked } }) => {
    if (type === 'checkbox') {
      value = checked;
    }
    setValues({ ...inputValues, [name]: value });
  };

  return { inputValues, handleChange, setValues };
}

export default useForm
