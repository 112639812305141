import React, { useContext, useState, useEffect, useRef } from 'react'
import { Box, Button, Avatar, Text } from '@chakra-ui/core'
import { RootContext } from '../../utils/root-context';
import { Editor, SerializeHTML} from '../editor';

function PageEdit (props) {
  const [ profileData, setProfileData ] = useState(false)
  const [ imageData, setImageData ] = useState({'profile': false, 'background': false})
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const [ isBgSubmitting, setIsBgSubmitting ] = useState(false)
  const [ isContentUpdateSubmitting, setIsContentUpdateSubmitting ] = useState(false)
  const [ editorContent, setEditorContent] = useState(false)

  const { authBody } = useContext(RootContext)
  const profileInputFileRef = useRef(null);
  const backgroundInputFileRef = useRef(null);
  const updateImageData = (data) => {
    const images = {};
    if (data['profile']['uploaded'] !== false) {
      images['profile'] = process.env.REACT_APP_URL + '/assets/upload/' + authBody.user_id + '/' + data['profile']['file'];
    }
    if (data['background']['uploaded'] !== false) {
      images['background'] = process.env.REACT_APP_URL + '/assets/upload/' + authBody.user_id + '/' + data['background']['file'];
    }

    setImageData(images)
  }

  const uploadFile = (file, fileType) => {
    const formData  = new FormData();
    formData.append('file', file);
    formData.append('request', JSON.stringify({ file_type : fileType }))

    fetch(process.env.REACT_APP_URL + '/v1/users-content/upload/' + authBody.user_id, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + authBody.jwt,
      },
      body: formData
    })
    .then(response => response.json())
    .then(result => {
      if (result.success === true) {
        updateImageData(result.data['upload_context'])
      }
    })
    .catch(error => console.error('Error:', error));
  }

  const handleProfileFileChange = () => {
    uploadFile(profileInputFileRef.current.files[0], 'profile')
  }

  const handleBackgroundFileChange = () => {
    uploadFile(backgroundInputFileRef.current.files[0], 'background')
  }

  const profileInputElement = () => {
    profileInputFileRef.current.click();
  }

  const backgroundInputElement = () => {
    backgroundInputFileRef.current.click();
  }

  const fetchUsers = () => {
    fetch(process.env.REACT_APP_URL + '/v1/users/id/' + authBody.user_id + '?users_content=true', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      }
    })
    .then(response => response.json())
    .then(result => {
      if (result.success === true) {
        setProfileData(result.data)
        setEditorContent(result.data['users_content']['context'])
        updateImageData(result.data['users_content']['upload_context'])
      }
    })
    .catch(error => console.error('Error:', error));
  }

  const updateContext = () => {
    setIsContentUpdateSubmitting(true)
    fetch(process.env.REACT_APP_URL + '/v1/users-content/' + authBody.user_id, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      },
      body: JSON.stringify({context: editorContent.json, context_html: SerializeHTML(editorContent)})
    })
    .then(response => response.json())
    .then(result => {
      setIsContentUpdateSubmitting(false)
      /*if (result.success === true) {

        updateImageData(result.data['upload_context'])
      }*/
    })
    .catch(error => console.error('Error:', error));

  }

  useEffect(
    () => {
      fetchUsers();
    }, []
  );

  return (
    <Box p="4">
      <Box d="flex" mb="4" p="8" bg="#F1F1F1" bgImage={'url(' + imageData['background'] + ')'} bgSize="cover" alignContent="center">
        <Box w="20%">
          <Avatar name={authBody.name} src={imageData['profile']} size="2xl" /><br />
          <input ref={profileInputFileRef} type="file" onChange={handleProfileFileChange} hidden={true} />
          <Button mt="8" variantColor="cyan" isLoading={isSubmitting} onClick={profileInputElement} size="xs" type="submit">
            Upload profile picture
          </Button>
        </Box>
        <Box w="55%"><Text color="#FFF" fontSize="4xl" fontWeight="bold" textShadow="2px 2px 2px #aaa">{authBody.name}</Text></Box>
        <Box textAlign="right" w="25%">
          <input ref={backgroundInputFileRef} type="file" onChange={handleBackgroundFileChange} hidden={true} />
          <Button variantColor="cyan" isLoading={isBgSubmitting} onClick={backgroundInputElement} size="xs" type="submit">
            Upload background
          </Button>
        </Box>

      </Box>
      <Box textAlign="right">
        <Button variantColor="cyan" isLoading={isContentUpdateSubmitting} onClick={updateContext} type="submit">
          SAVE
        </Button>
      </Box>
      {editorContent !== false ? <Editor editorContent={editorContent} setEditorContent={setEditorContent} /> : null }
    </Box>

  );
}

export default PageEdit
