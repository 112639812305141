import React, { useContext, useState, useEffect } from 'react'
import { Box, Stack, Text, Button } from '@chakra-ui/core'
import { RootContext } from '../../utils/root-context';

function Payment (props) {
  const [ paymentData, setPaymentData ] = useState(false)
  const [ bookingData, setBookingData ] = useState(false)
  const [ profileData, setProfileData ] = useState(false)
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const { authBody } = useContext(RootContext)

  const fetchPayments = () => {
    fetch(process.env.REACT_APP_URL + '/v1/payments/' + props.payment_id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      }
    })
    .then((response) => response.json())
    .then((result) => {
      if (result.success === true) {
        setPaymentData(result.data);
      }
    })
    .catch((error) => {
      console.error('Error:', error)
    });
  }

  const fetchBookings = () => {
    fetch(process.env.REACT_APP_URL + '/v1/bookings/' + paymentData.booking_id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      }
    })
    .then((response) => response.json())
    .then((result) => {
      if (result.success === true) {
        setBookingData(result.data)
        let user_id = result.data.user_id
        if (result.data.user_id === authBody.user_id) {
          user_id = result.data.to_user_id
        }
        fetchUsers(user_id);
      }
    })
    .catch((error) => {
      console.error('Error:', error)
    });
  }

  const fetchUsers = (user_id) => {
    fetch(process.env.REACT_APP_URL + '/v1/users/id/' + user_id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      }
    })
    .then((response) => response.json())
    .then((result) => {
      if (result.success === true) {
        setProfileData(result.data)
      }
    })
    .catch((error) => {
      console.error('Error:', error)
    });
  }

  const paymentClick = () => {
    setIsSubmitting(true)
  }

  useEffect(
    () => {
      fetchPayments()
    }, []
  );

  useEffect(
    () => {
      fetchBookings()
    }, [paymentData.payment_id]
  );




  return (
    <Box p="4" mx="auto" width={['100%', 0.8, 0.6]}>
      <Box d="flex" align="center">
        <Box w="50%" pr="4">
          { profileData ? <Text mb="4"><b>{ profileData.firstname + ' ' + profileData.lastname }</b></Text> : null }
          { bookingData ? <Text>This booking request is on <b>{ bookingData.on_date }</b> with price <b>€{ bookingData.price }</b><br />Venue is { bookingData.venue } and venue size is { bookingData.venue_size }</Text> : null }
          { bookingData.state === 9 && bookingData.user_id === authBody.user_id ? <><Text mt="4">You need to pay 10% of <b>€{ bookingData.price }</b> to finish booking!</Text><Button mt="4" variantColor="cyan" isLoading={ isSubmitting } onClick={ paymentClick }>Make a payment!</Button></> : null}
        </Box>
        <Box>
         Terms & conditions
        </Box>
      </Box>
    </Box>
  );
}

export default Payment
