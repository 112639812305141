import React, { useContext, useState, useEffect } from 'react'
import { Box, FormLabel, FormControl, Input, Textarea, Button } from "@chakra-ui/core";
import useForm from '../../utils/use-form';
import { RootContext } from '../../utils/root-context';

function ContactSend () {
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const { inputValues, handleChange, setValues } = useForm({content : '', sender_id: ''});

  const { authBody } = useContext(RootContext)

  const handleSubmit = () => {
    let postBody = {content: inputValues.content, sender_id: inputValues.sender_id}
    fetch(process.env.REACT_APP_URL + '/v1/contacts-send', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      },
      body: JSON.stringify(postBody)
    })
    .then(response => response.json())
    .then(result => {
      setIsSubmitting(false)
      if (result.success === true) {
        setValues({content: '', sender_id: ''});
      }
    })
    .catch(error => console.error('Error:', error));

  }

  useEffect(
    () => {
      //fetchUsers();
    }, []
  );

  return (
    <Box p="4"  mx="auto" width={0.5}>
      <FormControl mt={4} key="sender_id">
        <FormLabel htmlFor="content">Sender ID</FormLabel>
        <Input name="sender_id" onChange={handleChange} value={inputValues.sender_id} />
      </FormControl>
      <FormControl mt={4} key="content">
        <FormLabel htmlFor="content">Content</FormLabel>
        <Textarea placeholder="Type here..." name="content" onChange={handleChange} value={inputValues.content} />
      </FormControl>
      <Button mt={4} variantColor="cyan" isLoading={isSubmitting} type="submit" onClick={handleSubmit}>
        Send SMS-s
      </Button>
    </Box>

  );
}

export default ContactSend
