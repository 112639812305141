import React, { useContext } from 'react'
import { Link as RouterLink } from "react-router-dom";
import { Flex, Box, Link, Avatar, Popover, PopoverTrigger, PopoverContent, Stack, DarkMode } from "@chakra-ui/core";
import useUserAuth from '../../../utils/user-auth';
import { RootContext } from "../../../utils/root-context";

const Header = () => {
  const userAuthenticated = useUserAuth()
  const { authBody } = useContext(RootContext)

  const guestMenu = [{title:'Home', link: '/'}, {title:'Sign in', link: '/signin'}, {title:'Sign up', link: '/signup'}];
  const userMenu = [{title:'Calendar', link: '/dashboard'}, {title:'Search', link: '/user-list'}, {title:'Bookings', link: '/booking'} , {title:'Contacts', link: '/contact'}, {title:'Contacts send', link: '/contact-send'}];

  let menu = userAuthenticated ? userMenu : guestMenu

  const UserAvatar = (props) => {
    return (
      <Popover trigger="hover" placement="bottom-end">
        <PopoverTrigger>
          <Box>
            <Avatar name={authBody.name} />
          </Box>
        </PopoverTrigger>
        <DarkMode>
        <PopoverContent border="0" zIndex={4} width="300px" color="white">
          <Stack m={4} spacing={4}>
            <Link as={RouterLink} to="/profile">Profile</Link>
            <Link as={RouterLink} to="/profile-page">Profile page</Link>
            <Link as={RouterLink} to="/security">Security</Link>
            <Link as={RouterLink} to="/logout">Log out</Link>
          </Stack>
        </PopoverContent>
        </DarkMode>
      </Popover>
    )
  }

  return (
    <header>
      <Box w="100%" bg="gray.300">
        <Flex width={['100%', '100%', '100%', '100%', '80em']} mx="auto">
          <Box width="80%">
            <Stack isInline>
              {
                menu.map(item => {
                  return <Link as={RouterLink} to={item.link} p="5" key={item.link}>{item.title}</Link>
                })
              }
            </Stack>
          </Box>
          <Flex width="20%" pr="4" align="center" justify="flex-end">
            {userAuthenticated ?
              <UserAvatar />
              : null
            }
          </Flex>
        </Flex>
      </Box>
    </header>
  );
}

export default Header
