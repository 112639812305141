import { useState, useEffect, useContext } from "react";
import {RootContext} from "./root-context";

const useUserAuth = () => {
  const { authBody, setLoading } = useContext(RootContext);
  const [ isAuthenticated, setIsAuthenticated ] = useState(false);

  useEffect(() => {
    const exp_date = new Date(authBody.exp_date)
    const now_date = new Date()
    now_date.setHours(now_date.getHours()-2);
    setIsAuthenticated(exp_date > now_date)
    setLoading(false)
  }, [authBody.exp_date]);

  /*const signout = () => {
    setAuthBody({jwt: null, exp_date: null});
  }*/

  return isAuthenticated;
};

export default useUserAuth;
