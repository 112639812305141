import React, { useState, useContext } from "react";
import { Box, FormLabel, FormControl, Input, Button } from "@chakra-ui/core";
import { useHistory } from "react-router-dom";
import useForm from '../../utils/use-form';
import {RootContext} from "../../utils/root-context";

function Signin (props) {
  const { inputValues, handleChange } = useForm({email : '', password : ''});
  const [isSubmitting, setIsSubmitting] = useState(false)
  //const [jwt, setJWTData] = useLocalStorage('jwt', {jwt: null, exp_date: null});
  const {setAuthBody, setUserPolicies} = useContext(RootContext)
  const history = useHistory();

  const fetchPolicies = (props) => {
    console.log(props)
    fetch(process.env.REACT_APP_URL + '/v1/assignments/policies?user_id='+props.user.user_id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + props.jwt,
      }
    })
    .then((response) => response.json())
    .then((result) => {
      console.log('Success:', result)

      if (result.success === true) {
        setUserPolicies(result.data.policies)
        history.push('dashboard');
      } else {
        //props.setFormState({state: 'success', message: 'Your information has been submitted. Please follow the e-mail to complete your signup!'})
      }

      //setIsSubmitting(false);
    })
    .catch((error) => {
      console.error('Error:', error)
      //props.setFormState({state: 'error', message: 'There was an error submiting your form: ' + error})
      //setIsSubmitting(false)
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsSubmitting(true)
    fetch(process.env.REACT_APP_URL + '/v1/site/authentication', {
      method: 'POST',
      body: JSON.stringify(inputValues),
    })
    .then((response) => response.json())
    .then((result) => {
      console.log('Success:', result)

      if (result.success === true) {
        setAuthBody({jwt: result.data.jwt, exp_date: result.data.exp_date, user_id: result.data.user.user_id, name: result.data.user.firstname+' '+result.data.user.lastname});
        fetchPolicies(result.data)
      } else {
        //props.setFormState({state: 'success', message: 'Your information has been submitted. Please follow the e-mail to complete your signup!'})
      }

      //setIsSubmitting(false);
    })
    .catch((error) => {
      console.error('Error:', error)
      //props.setFormState({state: 'error', message: 'There was an error submiting your form: ' + error})
      //setIsSubmitting(false)
    });
  }

  return (
    <Box p={4} mx="auto" width={0.5}>
      <form onSubmit={handleSubmit}>
        <FormControl key="email">
          <FormLabel htmlFor="email">E-mail</FormLabel>
          <Input placeholder="E-mail" name="email" onChange={handleChange} value={inputValues['email']} />
        </FormControl>
        <FormControl mt={4} key="password">
          <FormLabel htmlFor="password">Password</FormLabel>
          <Input placeholder="Password" name="password"  type="password" onChange={handleChange} value={inputValues['password']} />
        </FormControl>
        <Button mt={4} variantColor="cyan" isLoading={isSubmitting} type="submit">
          Log in
        </Button>
      </form>
    </Box>
  );
}

export default Signin
