import React, {useState, useContext} from 'react'
import {
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    Switch
} from '@chakra-ui/core';
import useForm from '../../utils/use-form';
import {RootContext} from '../../utils/root-context';

function ModalContentOptions(props) {
  let initValues = {day_id: null, state: 0, price: '', city: '', country: 0, on_date: props.selectedDate}
  if (props.modalInitValue !== null) {
    initValues = {...initValues, ...props.modalInitValue}
  }
  const { inputValues, handleChange } = useForm(initValues);
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const { authBody } = useContext(RootContext)

  const handleClose = () => {
    if (isSubmitting) {
      return;
    }

    props.setModalContent(null)
    props.onClose()
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    setIsSubmitting(true)
    let url = process.env.REACT_APP_URL + '/v1/days'
    let method = 'POST'
    if (inputValues.day_id !== null) {
      url = url + '/' + inputValues.day_id
      method = 'PATCH'
    }
    fetch(url, {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      },
      body: JSON.stringify(inputValues),
    })
    .then((response) => response.json())
    .then((result) => {
      //console.log('Success:', result)

      if (result.success === true) {
        props.onClose()
        //props.setEvent([...props.events, inputValues])
        console.log(props.events)
        props.calendarUpdate()
        //history.push('dashboard');
      } else {
        //props.setFormState({state: 'success', message: 'Your information has been submitted. Please follow the e-mail to complete your signup!'})
      }

      //setIsSubmitting(false);
    })
    .catch((error) => {
      console.error('Error:', error)
      //props.setFormState({state: 'error', message: 'There was an error submiting your form: ' + error})
      //setIsSubmitting(false)
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <ModalContent>
        <ModalHeader>Set action for {props.selectedDate} </ModalHeader>
        <ModalCloseButton onClick={handleClose} />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>I'm occupied</FormLabel>
            <Switch size="lg" name="state" onChange={handleChange} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Set price for the day</FormLabel>
            <Input placeholder="Set price" name="price" type="number" onChange={handleChange} value={inputValues.price} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>City</FormLabel>
            <Input placeholder="Set city" name="city" onChange={handleChange} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Country</FormLabel>
            <Input placeholder="Set country" name="country" onChange={handleChange} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button ml={4} variantColor="cyan" isLoading={isSubmitting} type="submit">Save</Button>
        </ModalFooter>
      </ModalContent>
    </form>
  );
}

export default ModalContentOptions
