import React, { useContext, useState, useEffect, useRef } from 'react'
import { Box, Button, Textarea, Stack, Avatar, Text  } from '@chakra-ui/core'
import { DrawerCloseButton, DrawerHeader, DrawerBody, DrawerContent, DrawerFooter } from '@chakra-ui/core'
import { Tab, TabList, Tabs, TabPanel, TabPanels } from '@chakra-ui/core'
import useForm from '../../utils/use-form';
import { RootContext } from '../../utils/root-context';
import Booking from './tab-booking';

function Chat ({profileData, bookingData, setBookingData, isOpen}) {
  const [ chatData, setChatData ] = useState([])
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const [ chatErrors, setChatErros ] = useState(false)
  const { inputValues, handleChange, setValues } = useForm({content: ''});
  const { authBody } = useContext(RootContext)
  const contentRef = useRef(null)

  const fetchChats = () => {
    fetch(process.env.REACT_APP_URL + '/v1/chats?to_user_id=' + profileData.user_id + '&booking_id=' + bookingData.booking_id + '&limit=0', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      }
    })
    .then((response) => response.json())
    .then((result) => {
      if (result.success === true) {
        setChatData(result.data)
        contentRef.current.scrollTop = contentRef.current.scrollHeight;
      }
    })
    .catch((error) => {
      console.error('Error:', error)
    });
  }

  const postChats = () => {
    let postBody = {to_user_id: profileData.user_id, content: inputValues.content, booking_id: bookingData.booking_id}
    fetch(process.env.REACT_APP_URL + '/v1/chats', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      },
      body: JSON.stringify(postBody)
    })
    .then((response) => response.json())
    .then((result) => {
      if (result.success === true) {
        let chatPrev = chatData;
        chatPrev.push({...postBody, ...{chat_id: 100, created_at: 'NOW', user_id: authBody.user_id}})
        console.log(chatPrev);
        setChatData(chatPrev)
        setValues({content: ''})
        setIsSubmitting(false)
        contentRef.current.scrollTop = contentRef.current.scrollHeight;
      } else if (result.success === false) {
        setChatErros(result.message)
        setIsSubmitting(false)
      }
    })
    .catch((error) => {

      console.error('Error:', error)
    });
  }

  const sendClick = () => {
      postChats()
      setIsSubmitting(true)
  }

  const ChatItemReciver = ({chat_id, content, created_at}) => {
    return (
      <Stack align="center" borderTop="1px" borderColor="gray.100" p={2} isInline key={chat_id}>
        <Avatar size="sm" name={profileData.firstname + ' ' + profileData.lastname} />
        <Box pl={2} >
          <Text fontSize="xs" color="gray.200">{created_at}</Text>
          <Text fontSize="sm">{content}</Text>
        </Box>
      </Stack>
    )
  }

  const ChatItemSender = ({chat_id, content, created_at}) => {
    return (
      <Stack align="center" justify="flex-end" borderTop="1px" borderColor="gray.100" p={2} isInline key={chat_id}>
        <Box pr={2} textAlign="right">
          <Text fontSize="xs" color="gray.200">{created_at}</Text>
          <Text fontSize="sm">{content}</Text>
        </Box>
        <Avatar size="sm" name={authBody.name} />
       </Stack>
    )
  }

  useEffect(
    () => {
      fetchChats()
    }, []
  );

  return (
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>Booking</DrawerHeader>

      <DrawerBody maxHeight={'70%'}>
        <Box overflow="auto" maxHeight={'100%'} ref={contentRef}>
          {
            chatData.map(item => {
              return item.user_id === authBody.user_id ? <ChatItemSender key={item.chat_id} {...item} /> : <ChatItemReciver key={item.chat_id} {...item} />
            })
          }
        </Box>
      </DrawerBody>
      <DrawerFooter>
        <Tabs w={'100%'}>
          <TabList>
            <Tab>Chat</Tab>
            <Tab>Make a price offer</Tab>
            <Tab>Make booking</Tab>
          </TabList>
          <TabPanels pt={4} height={'100%'}>
            <TabPanel height="5rem">
              <Stack w={'100%'} height={'100%'} isInline>
                <Textarea placeholder="Type here..." name="content" onChange={handleChange} value={inputValues.content} />
                <Button color="blue" isLoading={isSubmitting} onClick={sendClick} height={'100%'}>Send</Button>
              </Stack>
              {chatErrors !== false ? <Text color="tomato" fontSize="sm" isTruncated>{chatErrors}</Text> : null}
            </TabPanel>
            <TabPanel>
              <p>two!</p>
            </TabPanel>
            <TabPanel>
              <Booking profileData={profileData} bookingData={bookingData} setBookingData={setBookingData} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </DrawerFooter>
    </DrawerContent>
  );
}

export default Chat
