import React, { useContext, useState, useEffect } from 'react'
import { Box, Stack, Text, Button } from '@chakra-ui/core'
import { useHistory } from "react-router-dom";
import Chat from '../user-chat/chat';
import ChatInput from '../user-chat/chat-input';
import { RootContext } from '../../utils/root-context';

function Booking (props) {
  const [ bookingData, setBookingData ] = useState(false)
  const [ profileData, setProfileData ] = useState(false)
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const [ chatData, setChatData ] = useState([])
  const { authBody } = useContext(RootContext)
  const history = useHistory();

  const fetchBookings = () => {
    fetch(process.env.REACT_APP_URL + '/v1/bookings/' + props.booking_id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      }
    })
    .then((response) => response.json())
    .then((result) => {
      if (result.success === true) {
        setBookingData(result.data)
        let user_id = result.data.user_id
        if (result.data.user_id === authBody.user_id) {
          user_id = result.data.to_user_id
        }
        fetchUsers(user_id);
      }
    })
    .catch((error) => {
      console.error('Error:', error)
    });
  }

  const fetchUsers = (user_id) => {
    fetch(process.env.REACT_APP_URL + '/v1/users/id/' + user_id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      }
    })
    .then((response) => response.json())
    .then((result) => {
      if (result.success === true) {
        setProfileData(result.data)
      }
    })
    .catch((error) => {
      console.error('Error:', error)
    });
  }

  const postBookings = () => {
    let postBody = {state: 9}
    fetch(process.env.REACT_APP_URL + '/v1/bookings/' + bookingData.booking_id, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      },
      body: JSON.stringify(postBody)
    })
    .then((response) => response.json())
    .then((result) => {
      if (result.success === true) {
        setBookingData({...bookingData, state: result.data.state})
        postPayments()
      }
    })
    .catch((error) => {
      console.error('Error:', error)
    });
  }

  const postPayments = () => {
    let postBody = {booking_id: bookingData.booking_id, state: 1}
    fetch(process.env.REACT_APP_URL + '/v1/payments/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      },
      body: JSON.stringify(postBody)
    })
    .then((response) => response.json())
    .then((result) => {
      if (result.success === true) {
        setIsSubmitting(false);
        history.push('/payment/' + result.data.payment_id);
      }
    })
    .catch((error) => {
      console.error('Error:', error)
    });
  }

  const acceptBookingClick = () => {
    postBookings()
    setIsSubmitting(true)
  }

  useEffect(
    () => {
      fetchBookings()
    }, []
  );


  return (
    <Box p="4">
      <Box d="flex" align="center">
        <Box w="50%" pr="4">
          { profileData ? <Text mb="4"><b>{ profileData.firstname + ' ' + profileData.lastname }</b></Text> : null }
          { bookingData ? <Text>This booking request is on <b>{ bookingData.on_date }</b> with price <b>€{ bookingData.price }</b><br />Venue is { bookingData.venue } and venue size is { bookingData.venue_size }</Text> : null }
          { bookingData.state === 5 ? <Button mt="4" variantColor="cyan" isLoading={ isSubmitting } onClick={ acceptBookingClick }>Accept booking!</Button> : null}
          { bookingData.state === 9 && bookingData.to_user_id === authBody.user_id ? <Text mt="4">You have accepted the request! Waiting user to make a payment</Text> : null}
          { bookingData.state === 9 && bookingData.user_id === authBody.user_id ? <><Text mt="4">Your request has been accepted!</Text><Button mt="4" variantColor="cyan" isLoading={ isSubmitting } onClick={ acceptBookingClick }>Go to payment page!</Button></> : null}
        </Box>
        <Box w="50%">
          { profileData && bookingData ? <Chat chatData={chatData} setChatData={setChatData} fullname={profileData.firstname + ' ' + profileData.lastname} toUserId={bookingData.to_user_id} bookingId={bookingData.booking_id} /> : null }
          <Box mt="8">
            { profileData && bookingData ? <ChatInput chatData={chatData} setChatData={setChatData} toUserId={bookingData.to_user_id} bookingId={bookingData.booking_id} /> : null }
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Booking
