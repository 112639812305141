import React, { useEffect, useState } from 'react';

const RootContext = React.createContext();

const RootContextProvider = ({children}) => {
  const prevAuthBody = JSON.parse(window.localStorage.getItem('authBody')) || JSON.stringify({jwt: null, exp_date: null, user_id: null, name: null});
  const prevPolicies = JSON.parse(window.localStorage.getItem('userPolicies')) || JSON.stringify({});
  const [ authBody, setAuthBody ] = useState(prevAuthBody);
  const [ userPolicies, setUserPolicies ] = useState(prevPolicies);
  const [ loading, setLoading ] = useState(true);

  useEffect(
    () => {
      window.localStorage.setItem('authBody', JSON.stringify(authBody));
    },
    [authBody]
  );

  useEffect(
    () => {
      window.localStorage.setItem('userPolicies', JSON.stringify(userPolicies));
    },
    [userPolicies]
  );

  const defaultContext = {
    authBody,
    setAuthBody,
    userPolicies,
    setUserPolicies,
    loading,
    setLoading
  };

  return (
    <RootContext.Provider value={defaultContext}>
      {children}
    </RootContext.Provider>
  );
};

export default RootContextProvider;
export {RootContext};
