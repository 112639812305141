import React from 'react'

import './loader.scss'

const Loader = () => {
  return (
    <div className="loader">
      <div className="loader-container">
        <div className="loader-item"></div>
        <div className="loader-item"></div>
        <div className="loader-item"></div>
        <div className="loader-item"></div>
        <div className="loader-item"></div>
        <div className="loader-item"></div>
        <div className="loader-item"></div>
      </div>
    </div>
  );
}

export default Loader
