import React, { useContext, useState, useEffect } from 'react'
import {Link as RouterLink} from "react-router-dom";
import { Box, Stack, Heading, Text, Avatar, Badge, Link } from "@chakra-ui/core";
import { RootContext } from '../../utils/root-context';

function Booking (props) {
  const [ bookingData, setBookingData ] = useState([])
  const { authBody } = useContext(RootContext)
  const stateList = {1: 'Booking initated',5: 'Booking accepted', 9: 'Waiting payment'}

  const fetchBookings = () => {
    fetch(process.env.REACT_APP_URL + '/v1/bookings?user_id=' + authBody.user_id + '&to_user_id=' + authBody.user_id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      }
    })
    .then((response) => response.json())
    .then((result) => {
      if (result.success === true) {
        setBookingData(result.data)
      }
    })
    .catch((error) => {
      console.error('Error:', error)
    });
  }

  const Item = ({ title, desc, url, state, ...rest }) => {
    return (
      <Link as={RouterLink} to={url} _hover={{textDecoration:"none"}}>
        <Box p={5} shadow="md" borderWidth="1px" borderColor="gray.50" {...rest}>
          <Box d="flex" align="center">
            <Heading fontSize="xl">{title}</Heading>
            <Box ml="2">
              <Badge>{state}</Badge>
            </Box>
          </Box>
          <Text>{desc}</Text>
        </Box>
      </Link>
    );
  }

  useEffect(
    () => {
      fetchBookings()
    }, []
  );


  return (
    <Box p="4">
      <Box mb="4" p={4} backgroundColor="gray.50">
        <Text>Search</Text>
      </Box>
      <Stack spacing={8}>
        {

          bookingData.map(item => {
            console.log(item)
            return(
              <Item
                title={item.on_date}
                desc={'Price ' + item.price}
                state={stateList[item.state]}
                url={'/booking/' + item.booking_id}
                key={item.booking_id}
              />
            )
          })
        }
      </Stack>
    </Box>
  );
}

export default Booking
