import React, {useState}  from "react";
import ProfileForm from "../profile/form";
import {Box, Alert, AlertIcon} from "@chakra-ui/core";

const Signup = () => {
  const [form, setFormState] = useState({state: null, message: null})
  const [formData, setFormData] = useState({account_type: null, firstname: null, lastname: null, phone: null, email: null, country: null, city: null})
  const [ isSubmitting, setIsSubmitting ] = useState(false)

  function onSubmit(data) {
    setIsSubmitting(true)
    fetch(process.env.REACT_APP_URL + '/v1/site/signup', {
      method: 'POST',
      body: JSON.stringify(data),
    })
    .then((response) => response.json())
    .then((result) => {
      //console.log('Success:', result)
      if (result.success === false) {
        console.log(data)
        setFormData(data)
        setFormState({state: 'error', message: 'Following errors are on the form: ' + result.message})
      } else {
        setFormState({state: 'success', message: 'Your information has been submitted. Please follow the e-mail to complete your signup!'})
      }

      setIsSubmitting(false);
    })
    .catch((error) => {
      //console.error('Error:', error)
      setFormState({state: 'error', message: 'There was an error submiting your form: ' + error})
      setIsSubmitting(false)
    });
  }

  const FormAlert = ({status, message}) => {
    return(
      <Box mx="auto" mt={4} mb={5} width={['100%', 0.6, 0.3]}>
        <Alert status={status}>
          <AlertIcon />
          {message}
        </Alert>
      </Box>
    )
  }

  return ([
    form.state !== null && <FormAlert key="1" status={form.state} message={form.message} />,
    form.state === null || form.state === 'error' ? <ProfileForm key="2" formData={formData} onSubmit={onSubmit} isSubmitting={isSubmitting}  /> : null
  ]);
}


export default Signup

