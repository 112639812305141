import React, { useContext, useState, useRef } from 'react'
import { Button, Textarea, Stack, Text  } from '@chakra-ui/core'
import useForm from '../../utils/use-form';
import { RootContext } from '../../utils/root-context';

function ChatInput ({chatData, setChatData, toUserId, bookingId}) {
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const [ chatErrors, setChatErros ] = useState(false)
  const { inputValues, handleChange, setValues } = useForm({content: ''});
  const { authBody } = useContext(RootContext)
  const contentRef = useRef(null)

  const postChats = () => {
    let postBody = {to_user_id: toUserId, content: inputValues.content, booking_id: bookingId}
    fetch(process.env.REACT_APP_URL + '/v1/chats', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      },
      body: JSON.stringify(postBody)
    })
    .then((response) => response.json())
    .then((result) => {
      if (result.success === true) {
        let chatPrev = chatData;
        chatPrev.push({...postBody, ...{chat_id: 100, created_at: 'NOW', user_id: authBody.user_id}})
        console.log(chatPrev);
        setChatData(chatPrev)
        setValues({content: ''})
        setIsSubmitting(false)
        contentRef.current.scrollTop = contentRef.current.scrollHeight;
      } else if (result.success === false) {
        setChatErros(result.message)
        setIsSubmitting(false)
      }
    })
    .catch((error) => {

      console.error('Error:', error)
    });
  }

  const sendClick = () => {
      postChats()
      setIsSubmitting(true)
  }

  return (
    <>
      <Stack w={'100%'} height={'100%'} isInline>
        <Textarea placeholder="Type here..." name="content" onChange={handleChange} value={inputValues.content} />
        <Button color="blue" isLoading={isSubmitting} onClick={sendClick}>Send</Button>
      </Stack>
      {chatErrors !== false ? <Text color="tomato" fontSize="sm" isTruncated>{chatErrors}</Text> : null}
    </>
  )
}

export default ChatInput
