import React, {useContext} from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider, CSSReset, Box } from "@chakra-ui/core";
import Header from './header';
import Loader from '../loader';
import useUserAuth from '../../utils/user-auth';
import {RootContext} from '../../utils/root-context';
import Signup from '../../routes/signup';
import Signin from '../../routes/signin';
import Profile from '../../routes/profile';
import ProfilePageEdit from '../../routes/profile/page-edit';
import Booking from '../../routes/booking';
import Payment from '../../routes/payment';
import Dashboard from '../../routes/dashboard';
import UserList from '../../routes/user-list';
import Contact from '../../routes/contact';
import ContactSend from '../../routes/contact/send';

const App = () => {
  const {loading, setAuthBody} = useContext(RootContext);
  const userAuthenticated = useUserAuth();

  const LogOut = () => {
    setAuthBody({jwt: null, exp_date: null, user_id: null})
    return (
      <Redirect to="/signin" />
    )
  }

  const PrivateRoute = ({ children, ...rest }) => {
    return (
      <Route async
        {...rest}
        render={({ location }) =>
          userAuthenticated ? (
            children
          ) : (
            <Redirect to={{ pathname: "/signin", state: { from: location } }} />
          )
        }
      />
    )
  }

  return (
      <Router>
        {loading ? <Loader /> : (
          <ThemeProvider>
            <CSSReset />
            <Header />
            <Box mx="auto" width={['100%', '100%', '100%', '100%', '80em']}>
              <Switch>
                <Route exact path="/signin">
                  <Signin  />
                </Route>
                <Route path="/signup">
                  <Signup />
                </Route>
                <PrivateRoute path="/logout">
                  <LogOut />
                </PrivateRoute>
                <PrivateRoute path="/dashboard">
                  <Dashboard />
                </PrivateRoute>
                <PrivateRoute path="/contact">
                  <Contact />
                </PrivateRoute>
                <PrivateRoute path="/contact-send">
                  <ContactSend />
                </PrivateRoute>
                <PrivateRoute path="/profile/:profile_url">
                  <Profile />
                </PrivateRoute>
                <PrivateRoute path="/profile">
                  <Profile />
                </PrivateRoute>
                <PrivateRoute path="/profile-page">
                  <ProfilePageEdit />
                </PrivateRoute>
                <PrivateRoute path="/user-list">
                  <UserList />
                </PrivateRoute>
                <PrivateRoute path="/booking/:booking_id">
                  <Booking />
                </PrivateRoute>
                <PrivateRoute path="/booking">
                  <Booking />
                </PrivateRoute>
                <PrivateRoute path="/payment/:payment_id">
                  <Payment />
                </PrivateRoute>
              </Switch>
            </Box>
          </ThemeProvider>
        ) }
      </Router>
  );
}

export default App;
