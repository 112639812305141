import React, { useMemo, useState } from 'react';
import { createEditor, Node } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, withReact } from 'slate-react';
import {
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  FormatStrikethrough,
  FormatUnderlined,
  Image,
  Link,
  LooksOne,
  LooksTwo,
  Subscript,
  Superscript
} from '@styled-icons/material';
import { CodeAlt } from '@styled-icons/boxicons-regular/CodeAlt';
import { CodeBlock } from '@styled-icons/boxicons-regular/CodeBlock';
import {
  AlignPlugin,
  BlockquotePlugin,
  BoldPlugin,
  CodeBlockPlugin,
  EditablePlugins,
  HeadingPlugin,
  HeadingToolbar,
  ImagePlugin,
  ItalicPlugin,
  LinkPlugin,
  ListPlugin,
  MediaEmbedPlugin,
  ParagraphPlugin,
  StrikethroughPlugin,
  SubscriptPlugin,
  SuperscriptPlugin,
  TablePlugin,
  UnderlinePlugin,
  ToolbarAlign,
  ToolbarElement,
  ToolbarImage,
  ToolbarLink,
  ToolbarList,
  ToolbarMark,
  MARK_BOLD,
  MARK_CODE,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_SUBSCRIPT,
  MARK_SUPERSCRIPT,
  MARK_UNDERLINE,
  withImageUpload,
  withInlineVoid,
  withLink,
  withTable,
  withToggleType,
  pipe,
} from '@udecode/slate-plugins';
import { serializeHTMLFromNodes } from '@udecode/slate-plugins'
import { options } from './options';

const plugins = [
  AlignPlugin(), BlockquotePlugin(), BoldPlugin(), CodeBlockPlugin(), HeadingPlugin(options), ImagePlugin(), ItalicPlugin(), LinkPlugin(), ListPlugin(), MediaEmbedPlugin(), ParagraphPlugin(options), StrikethroughPlugin(), SubscriptPlugin(), SuperscriptPlugin(), TablePlugin(), UnderlinePlugin()
];

const withPlugins = [
  withReact,
  withHistory,
  withTable(options),
  withLink(),
  withInlineVoid({ plugins }),
  withToggleType({ defaultType: options.p.type }),
  withImageUpload()
];

export const Editor = ({editorContent, setEditorContent}) => {
  const editor = useMemo(() => pipe(createEditor(), ...withPlugins), []);

  return (
    <Slate
      editor={editor}
      value={editorContent}
      onChange={value => setEditorContent(value)}
    >
      <HeadingToolbar styles={{ root: { flexWrap: 'wrap' } }}>
        {/* Elements */}
        <ToolbarElement type={options.h1.type} icon={<LooksOne />} />
        <ToolbarElement type={options.h2.type} icon={<LooksTwo />} />
        <ToolbarList
          {...options}
          typeList={options.ul.type}
          icon={<FormatListBulleted />}
        />
        <ToolbarList
          {...options}
          typeList={options.ol.type}
          icon={<FormatListNumbered />}
        />
        <ToolbarElement
          type={options.blockquote.type}
          icon={<FormatQuote />}
        />
        <ToolbarElement type={options.code_block.type} icon={<CodeBlock />} />

        {/* Marks */}
        <ToolbarMark type={MARK_BOLD} icon={<FormatBold />} />
        <ToolbarMark type={MARK_ITALIC} icon={<FormatItalic />} />
        <ToolbarMark type={MARK_UNDERLINE} icon={<FormatUnderlined />} />
        <ToolbarMark
          type={MARK_STRIKETHROUGH}
          icon={<FormatStrikethrough />}
        />
        <ToolbarMark type={MARK_CODE} icon={<CodeAlt />} />
        <ToolbarMark
          type={MARK_SUPERSCRIPT}
          clear={MARK_SUBSCRIPT}
          icon={<Superscript />}
        />
        <ToolbarMark
          type={MARK_SUBSCRIPT}
          clear={MARK_SUPERSCRIPT}
          icon={<Subscript />}
        />

        <ToolbarAlign icon={<FormatAlignLeft />} />
        <ToolbarAlign
          type={options.align_center.type}
          icon={<FormatAlignCenter />}
        />
        <ToolbarAlign
          type={options.align_right.type}
          icon={<FormatAlignRight />}
        />
        <ToolbarLink {...options} icon={<Link />} />
        <ToolbarImage {...options} icon={<Image />} />
      </HeadingToolbar>
      <EditablePlugins plugins={plugins} placeholder="Enter some text..." />
    </Slate>
  );
};

export const SerializeHTML = (editorValue) => {
  return serializeHTMLFromNodes(plugins)(editorValue)
}

export default { Editor, SerializeHTML }
