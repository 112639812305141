import React, { useContext, useState, useEffect, useRef } from 'react'
import { Box, Stack, Avatar, Text  } from '@chakra-ui/core'
import { Drawer, DrawerOverlay, DrawerContent } from '@chakra-ui/core'
import useForm from '../../utils/use-form';
import { RootContext } from '../../utils/root-context';
import Loader from '../loader';
import BookingInitialize from './booking-init';
import Chat from './chat';

function Booking ({profileData, isOpen, onClose, onDate}) {
  const [ bookingData, setBookingData ] = useState({on_date: false, state: false, venue: '', venue_size: '', price: false, booking_id: false});
  const { authBody } = useContext(RootContext)

  const fetchDaysData = () => {
    fetch(process.env.REACT_APP_URL + '/v1/days?user_id=' + profileData.user_id + '&state=0&on_date=' + bookingData.on_date, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      }
    })
    .then((response) => response.json())
    .then((result) => {
      if (result.success === true) {
        setBookingData({...bookingData, price: result.data[0].price})
      }
    })
    .catch((error) => {
      console.error('Error:', error)
    });
  }

  useEffect(
    () => {
      if (bookingData.on_date !== false) {
        fetchDaysData();
      }
    }, [bookingData.on_date]
  );

  useEffect(
    () => {
      setBookingData({...bookingData, on_date: onDate})
    }, [onDate]
  );


  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size="md"
      closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      {(bookingData.on_date === false || bookingData.price === false || bookingData.booking_id === false) ? (<BookingInitialize profileData={profileData} bookingData={bookingData} setBookingData={setBookingData} />) : null}
      {(bookingData.on_date !== false && bookingData.price !== false && bookingData.booking_id !== false) ? (<Chat profileData={profileData} bookingData={bookingData} setBookingData={setBookingData} isOpen={isOpen} />) : null}

    </Drawer>
  );
}

export default Booking

