import React, { useState, useContext, useEffect } from "react";
import {Link as RouterLink} from "react-router-dom";
import { Box, Stack, Heading, Text, Avatar, Badge, Link } from "@chakra-ui/core";
import useForm from '../../utils/use-form';
import {RootContext} from "../../utils/root-context";

function ContactList (props) {
  const { inputValues, handleChange } = useForm({email : '', password : ''});
  const [listData, setListData] = useState([])
  const {authBody} = useContext(RootContext)

  const fetchContacts = () => {
    fetch(process.env.REACT_APP_URL + '/v1/contacts?user_id=' + authBody.user_id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      }
    })
    .then((response) => response.json())
    .then((result) => {
      console.log('Success:', result)

      if (result.success === true) {
        setListData(result.data)
      } else {
        //props.setFormState({state: 'success', message: 'Your information has been submitted. Please follow the e-mail to complete your signup!'})
      }

      //setIsSubmitting(false);
    })
    .catch((error) => {
      console.error('Error:', error)
      //props.setFormState({state: 'error', message: 'There was an error submiting your form: ' + error})
      //setIsSubmitting(false)
    });
  }

  const Item = ({ id, name, email, phone, ...rest }) => {
    return (
      <Link as={RouterLink} to={"/contact-content/" + id} _hover={{textDecoration:"none"}}>
          <Box d="flex" p={2} shadow="md" borderWidth="1px" borderColor="gray.50" {...rest}>
            <Text fontWeight="bold" pr={2}>{name}</Text>
            <Text  pr={2}>{email}</Text>
            <Text>{phone}</Text>
          </Box>
      </Link>
    );
  }

  useEffect(
    () => {
      fetchContacts();
    }, []
  );

  return (
    <Box p={4}>
      <Box mb="4" p={4} backgroundColor="gray.50">
        <Text>Search</Text>
      </Box>
      <Stack spacing={4}>
        {
          listData.map(item => {
            return(
              <Item
                id={item.contact_id}
                title={item.name}
                phone={item.phone}
                email={item.email}
                key={item.contact_id}
              />
            )
          })
        }
      </Stack>
    </Box>
  );
}

export default ContactList
