import React, { useState, useContext, useEffect } from "react";
import {Link as RouterLink} from "react-router-dom";
import { Box, Stack, Heading, Text, Avatar, Badge, Link } from "@chakra-ui/core";
import useForm from '../../utils/use-form';
import {RootContext} from "../../utils/root-context";

function UserList (props) {
  const { inputValues, handleChange } = useForm({email : '', password : ''});
  const [listData, setListData] = useState([])
  const {setAuthBody, setUserPolicies, authBody} = useContext(RootContext)

  const fetchPolicies = () => {
    fetch(process.env.REACT_APP_URL + '/v1/users?account_type=1', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      }
    })
    .then((response) => response.json())
    .then((result) => {
      console.log('Success:', result)

      if (result.success === true) {
        setListData(result.data)
      } else {
        //props.setFormState({state: 'success', message: 'Your information has been submitted. Please follow the e-mail to complete your signup!'})
      }

      //setIsSubmitting(false);
    })
    .catch((error) => {
      console.error('Error:', error)
      //props.setFormState({state: 'error', message: 'There was an error submiting your form: ' + error})
      //setIsSubmitting(false)
    });
  }

  const Item = ({ title, desc, url, ...rest }) => {
    return (
      <Link as={RouterLink} to={"/profile/" + url} _hover={{textDecoration:"none"}}>
        <Box d="flex" p={5} shadow="md" borderWidth="1px" borderColor="gray.50" {...rest}>
          <Avatar name={title} />
          <Box ml="2">
            <Box d="flex" align="center">
              <Heading fontSize="xl">{title}</Heading>
              <Box ml="2">
                <Stack isInline>
                  <Badge>Deep House</Badge>
                  <Badge>Progressive House</Badge>
                  <Badge>Techno</Badge>
                </Stack>
              </Box>

            </Box>
            <Text>{desc}</Text>
          </Box>
        </Box>
      </Link>
    );
  }

  useEffect(
    () => {
      fetchPolicies();
    }, []
  );

  return (
    <Box p={4}>
      <Box mb="4" p={4} backgroundColor="gray.50">
        <Text>Search</Text>

      </Box>
      <Stack spacing={8}>
        {
          listData.map(item => {
            return(
              <Item
                title={item.firstname+' '+item.lastname}
                desc="The future can be even brighter but a goal without a plan is just a wish"
                url={item.profile_url}
                key={item.user_id}
              />
            )
          })
        }
      </Stack>
    </Box>
  );
}

export default UserList
