import React, {useState, useContext} from 'react'
import {
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    Switch,
    Grid,
    Checkbox,
    Box,
    Flex,
    Popover,
    PopoverTrigger,
    PopoverContent
} from '@chakra-ui/core';


import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import useForm from '../../utils/use-form';
import {RootContext} from '../../utils/root-context';

function ModalContentBulk(props) {
  const { inputValues, handleChange, setValues } = useForm({from_date: '', to_date: '', state: false, overwrite: false, price: '', day_1: false, day_2: false, day_3: false, day_4: false, day_5: false, day_6: false, day_7: false});
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const { authBody } = useContext(RootContext)
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const handleClose = () => {
    if (isSubmitting) {
      return;
    }

    props.setModalContent(null)
    props.onClose()
  }

  const handleDateChange = (value) => {
    setValues({ ...inputValues, from_date: value[0].getFullYear() + '-' + (value[0].getMonth() + 1) + '-' + value[0].getDate(), to_date: value[1].getFullYear() + '-' + (value[1].getMonth() + 1) + '-' + value[1].getDate() })
    close()
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsSubmitting(true)
    fetch(process.env.REACT_APP_URL + '/v1/days/bulk', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      },
      body: JSON.stringify(inputValues),
    })
    .then((response) => response.json())
    .then((result) => {
      //console.log('Success:', result)

      if (result.success === true) {
        props.onClose()
        //props.setEvent([...props.events, inputValues])
        console.log(props.events)
        props.calendarUpdate()
        //history.push('dashboard');
      } else {
        //props.setFormState({state: 'success', message: 'Your information has been submitted. Please follow the e-mail to complete your signup!'})
      }

      //setIsSubmitting(false);
    })
    .catch((error) => {
      console.error('Error:', error)
      //props.setFormState({state: 'error', message: 'There was an error submiting your form: ' + error})
      //setIsSubmitting(false)
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <ModalContent>
        <ModalHeader>Set bulk action</ModalHeader>
        <ModalCloseButton onClick={handleClose} />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>Date range</FormLabel>
            <Flex align="center">
              <Box>
                <Popover
                  isOpen={isOpen}
                  onClose={close}
                  placement="bottom-start"
                >
                  <PopoverTrigger>
                    <Input placeholder="From date" name="from_date" type="text" onClick={open} onChange={handleChange} value={inputValues.from_date} autoComplete="off" />
                  </PopoverTrigger>
                  <PopoverContent zIndex={4}>
                    <Calendar selectRange={true} onChange={handleDateChange} />
                  </PopoverContent>
              </Popover>
              </Box>
              <Box m="2">––</Box>
              <Box>
                  <Input placeholder="To date" name="to_date" type="text" onClick={open} onChange={handleChange} value={inputValues.to_date} />
              </Box>
            </Flex>
          </FormControl>
          <FormControl mt={4}>
            <Grid templateColumns="repeat(3, 1fr)" gap={4}>
              {
                ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((v,k) => {

                  const name = 'day_' + (k + 1)
                  return (
                    <Box key={name}>
                      <Checkbox  value="1" name={name} onChange={handleChange}>{v}</Checkbox>
                    </Box>
                  )
                })
              }
            </Grid>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Set price for the day</FormLabel>
            <Input placeholder="Set price" name="price" type="number" onChange={handleChange} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>City</FormLabel>
            <Input placeholder="Set city" name="city" onChange={handleChange} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Country</FormLabel>
            <Input placeholder="Set country" name="country" onChange={handleChange} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>I'm occupied</FormLabel>
            <Switch size="lg" name="state" onChange={handleChange} value="1" />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Overwrite data during selected dates</FormLabel>
            <Switch size="lg" name="overwrite" onChange={handleChange} value="1" />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button ml={4} variantColor="cyan" isLoading={isSubmitting} type="submit">Save</Button>
        </ModalFooter>
      </ModalContent>

    </form>
  );
}

export default ModalContentBulk
