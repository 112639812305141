import React, { useContext, useState, useEffect, useRef } from 'react'
import { Box, Button, FormControl, FormLabel, Input, Text, Popover, PopoverContent  } from '@chakra-ui/core'
import { DrawerCloseButton, DrawerHeader, DrawerBody, DrawerContent, DrawerFooter } from '@chakra-ui/core'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import useForm from '../../utils/use-form';
import { RootContext } from '../../utils/root-context';


function Chat ({profileData, bookingData, setBookingData}) {
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { inputValues, handleChange } = useForm({venue_size: '', venue: ''});
  const { authBody } = useContext(RootContext)

  const openCalendar = () => setIsPopoverOpen(true);
  const closeCalendar = () => setIsPopoverOpen(false);

  const handleDateChange = (value) => {
    setBookingData({ ...bookingData, on_date: value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate()})
    closeCalendar()
  }

  const postBookingData = () => {
    fetch(process.env.REACT_APP_URL + '/v1/bookings/init', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authBody.jwt,
      },
      body: JSON.stringify({to_user_id : profileData.user_id, on_date : bookingData.on_date, price: bookingData.price, context:{venue: inputValues.venue, venue_size: inputValues.venue_size}}),
    })
    .then((response) => response.json())
    .then((result) => {
      if (result.success === true) {
        setBookingData({...bookingData, state: result.data.state, booking_id: result.data.booking_id})
        //setChatData(result.data)
        //contentRef.current.scrollTop = contentRef.current.scrollHeight;
      }
    })
    .catch((error) => {
      console.error('Error:', error)
    });
  }

  const startBookingClick = () => {
    postBookingData()
    setIsSubmitting(true)

  }

  return (
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>Make a booking with {profileData.firstname + ' ' + profileData.lastname}</DrawerHeader>

      <DrawerBody>
        <Box>
          <FormLabel>Select booking date:</FormLabel>
          <Box pl={2} as="button" onClick={openCalendar}>
            { bookingData.on_date !== false ? bookingData.on_date : '........' }
          </Box>
        </Box>
        <Box>
          <FormControl mt={4}>
            <FormLabel>Set venue name</FormLabel>
            <Input w="20rem" placeholder="Venue name" name="venue" type="text" value={inputValues.venue} onChange={handleChange} />
          </FormControl>
        </Box>
        <Box>
          <FormControl mt={4}>
            <FormLabel>Set venue size</FormLabel>
            <Input w="10rem" placeholder="Approximate number" name="venue_size" type="number" value={inputValues.venue_size} onChange={handleChange} />
          </FormControl>
        </Box>
        <Popover
          isOpen={isPopoverOpen}
          onClose={closeCalendar}
          placement="bottom-start"
        >
          <PopoverContent zIndex={4}>
            <Calendar onChange={handleDateChange} />
          </PopoverContent>
        </Popover>
      </DrawerBody>
      <DrawerFooter>
        <Text></Text>
        <Button variantColor="cyan" isLoading={isSubmitting} onClick={startBookingClick} >Start booking</Button>
      </DrawerFooter>
    </DrawerContent>
  );
}

export default Chat
