import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, FormErrorMessage, FormLabel, FormControl, Input, Select, Button } from '@chakra-ui/core';

function ProfileForm ({onSubmit, isSubmitting, formData}) {
  const { handleSubmit, errors, register } = useForm()

  const accountOptions = [{id: 1, label: 'Artist'}, {id:2, label: 'Booking agent / Venue'}, {id: 3, label: 'Manager'}]
  const countryOptions = [{id: 1, label: 'Estonia'}, {id:2, label: 'Germany'}]

  return (
    <Box p={4} mx="auto" width={['100%', 0.6, 0.3]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors['account_type']}>
          <FormLabel htmlFor="account_type">Account type</FormLabel>
            <Select placeholder="Select account type" name="account_type" defaultValue={formData['account_type']} ref={register}>
              {accountOptions.map(item => <option key={item.id} value={item.id}>{item.label}</option>)}
            </Select>
          <FormErrorMessage>
            {errors['account_type'] && errors['account_type'].message}
          </FormErrorMessage>
        </FormControl>
        <FormControl mt={4} isInvalid={errors['email']}>
          <FormLabel htmlFor="email">E-mail</FormLabel>
            <Input name="email" placeholder="E-mail" type="email" defaultValue={formData['email']} ref={register} />
          <FormErrorMessage>
            {errors['email'] && errors['email'].message}
          </FormErrorMessage>
        </FormControl>
        <FormControl mt={4} isInvalid={errors['firstname']}>
          <FormLabel htmlFor="firstname">Firstname</FormLabel>
            <Input name="firstname" placeholder="Firstname" type="text" defaultValue={formData['firstname']} ref={register} />
          <FormErrorMessage>
            {errors['firstname'] && errors['firstname'].message}
          </FormErrorMessage>
        </FormControl>
        <FormControl mt={4} isInvalid={errors['lastname']}>
          <FormLabel htmlFor="lastname">Lastname</FormLabel>
            <Input name="lastname" placeholder="Lastname" type="text" defaultValue={formData['lastname']} ref={register} />
          <FormErrorMessage>
            {errors['lastname'] && errors['lastname'].message}
          </FormErrorMessage>
        </FormControl>
        <FormControl mt={4} isInvalid={errors['phone']}>
          <FormLabel htmlFor="phone">Phone</FormLabel>
            <Input name="phone" placeholder="Phone" type="tel" defaultValue={formData['phone']} ref={register} />
          <FormErrorMessage>
            {errors['phone'] && errors['phone'].message}
          </FormErrorMessage>
        </FormControl>
        <FormControl mt={4} isInvalid={errors['country']}>
          <FormLabel htmlFor="country">Country</FormLabel>
            <Select placeholder="Select country" name="country" defaultValue={formData['country']} ref={register}>
              {countryOptions.map(item => <option key={item.id} value={item.id}>{item.label}</option>)}
            </Select>
          <FormErrorMessage>
            {errors['country'] && errors['country'].message}
          </FormErrorMessage>
        </FormControl>
        <FormControl mt={4} isInvalid={errors['city']}>
          <FormLabel htmlFor="city">City</FormLabel>
            <Input name="city" placeholder="City" type="text" defaultValue={formData['city']} ref={register} />
          <FormErrorMessage>
            {errors['city'] && errors['city'].message}
          </FormErrorMessage>
        </FormControl>
        <Button mt={4} variantColor="cyan" isLoading={isSubmitting} type="submit">
          Submit
        </Button>
      </form>
    </Box>
  );
}

export default ProfileForm
